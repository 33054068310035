import React from 'react';
import { Layout, Button, Input, Form, Row, Col, Card, message, Space } from 'antd';
import { ReactComponent as Logo } from '../assets/twilio-mark-red.svg';
import { useAuthContext } from '../AuthContext';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const { Content } = Layout;

const LoginPage = () => {
    const { login, signup, authStatus } = useAuthContext();
    const [form] = Form.useForm();

    const handleLogin = async ({ username, password }) => {
        try {
            await login(username, password);
            message.success('Login successful');
        } catch (error) {
            message.error(error.message);
        }
    };

    const handleSignup = async () => {
        const values = form.getFieldsValue();
        const { username, password } = values;
        try {
            await signup(username, password);
            message.success('Signup successful');
        } catch (error) {
            message.error(error.message);
        }
    };

    const isConnecting = authStatus === 'connecting';

    return (
        <Layout>
            <Content style={{ height: '100vh' }}>
                <Row justify="space-around" align="middle" style={{ height: '100%' }}>
                    <Col span={12} offset={6}>
                        <Card style={{ maxWidth: '404px' }}>
                            <Row justify="center" align="middle" style={{ marginBottom: '30px' }}>
                                <Logo/>
                            </Row>

                            <Form form={form} onFinish={handleLogin}>
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                        placeholder="Username"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password 
                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}
                                        placeholder="Password" 
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Button 
                                            block 
                                            type="primary" 
                                            htmlType="submit"
                                            loading={isConnecting}
                                            disabled={isConnecting}
                                        >
                                            Login
                                        </Button>
                                        <Button 
                                            block 
                                            onClick={handleSignup}
                                            disabled={isConnecting}
                                        >
                                            Sign up
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default LoginPage;