import React from 'react';
import { Layout, Typography, Button, Tooltip, Modal, Input } from 'antd';
import { FormOutlined, CloseOutlined, DeleteOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { useDataContext } from '../DataContext';
import { useState } from 'react';

const { Header } = Layout;
const { Title } = Typography;

const ConversationHeader = ({ onInfoClick, isSidebarVisible }) => {
  const { selectedConversationSid, contactInfo, deleteConversation } = useDataContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState('');

  const getHeaderText = () => {
    if (!selectedConversationSid) return 'No Conversation Selected';
    const currentContactInfo = contactInfo[selectedConversationSid];
    if (currentContactInfo) {
      return currentContactInfo.name || currentContactInfo.phoneNumber;
    }
    return selectedConversationSid;
  };

  const handleDelete = async () => {
    if (selectedConversationSid) {
      try {
        await deleteConversation(selectedConversationSid);
      } catch (error) {
        console.error('Error deleting conversation:', error);
      }
    }
  };

  const handleAddWhatsAppParticipant = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    try {
      const response = await fetch('/add-whatsapp-participant', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversationSid: selectedConversationSid,
          whatsappNumber: whatsappNumber,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to add WhatsApp participant');
      }
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error('Error adding WhatsApp participant:', error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Header style={{ 
        background: '#fff', 
        padding: '0 24px', 
        borderBottom: '1px solid #e8e8e8',
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Title level={5} style={{ margin: '16px 0', fontSize: '1.15em' }}>
          {getHeaderText()}
        </Title>
        {selectedConversationSid && (
          <div>
            <Tooltip title="Add WhatsApp Participant">
              <Button 
                icon={<WhatsAppOutlined />}
                onClick={handleAddWhatsAppParticipant}
                type="link"
              />
            </Tooltip>
            <Tooltip title={isSidebarVisible ? "Close Contact Info" : "View/Edit Contact Info"}>
              <Button 
                icon={isSidebarVisible ? <CloseOutlined /> : <FormOutlined />}
                onClick={onInfoClick}
                type="link"
              />
            </Tooltip>
            <Tooltip title="Delete Conversation">
              <Button
                icon={<DeleteOutlined />}
                onClick={handleDelete}
                type="link"
                danger
              />
            </Tooltip>
          </div>
        )}
      </Header>
      <Modal
        title="Add WhatsApp Participant"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Enter WhatsApp number (e.g., +14155238886)"
          value={whatsappNumber}
          onChange={(e) => setWhatsappNumber(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default ConversationHeader;