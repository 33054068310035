import React, { createContext, useState, useContext, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { CloudUploadOutlined } from '@ant-design/icons';
import styles from '../assets/Conversation.module.css';
import { useDataContext } from '../DataContext';

export const FileUploadContext = createContext();

export const useFileUpload = () => useContext(FileUploadContext);

const FileUploadZone = ({ children }) => {
  const [attachedFile, setAttachedFile] = useState(null);
  const { selectedConversationSid } = useDataContext();

  useEffect(() => {
    setAttachedFile(null);
  }, [selectedConversationSid]);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (isAllowedFileType(file)) {
        setAttachedFile(file);
      } else {
        console.error('File type not allowed:', file.type);
      }
    }
  };

  const clearFile = () => setAttachedFile(null);

  return (
    <FileUploadContext.Provider value={{ attachedFile, setAttachedFile, clearFile }}>
      <Dropzone
        onDrop={onDrop}
        accept={getAllowedFileTypes()}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            onClick={() => {}}
            id="OpenChannel"
            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          >
            {isDragActive && (
              <div className={styles.drop}>
                <CloudUploadOutlined style={{ fontSize: "5em", color: "#fefefe" }} />
                <h3 style={{ color: "#fefefe" }}>Release to Upload</h3>
              </div>
            )}
            <div
              className={styles.messages}
              style={{
                filter: `blur(${isDragActive ? 4 : 0}px)`,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
              }}
            >
              <input id="files" {...getInputProps()} />
              {children}
            </div>
          </div>
        )}
      </Dropzone>
    </FileUploadContext.Provider>
  );
};

export default FileUploadZone;

// Utility functions and constants
const allowedFileTypes = {
  // Image Formats
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/bmp': ['.bmp'],
  'image/webp': ['.webp'],

  // Video Formats
  'video/mp4': ['.mp4'],
  'video/3gpp': ['.3gp'],

  // Audio Formats
  'audio/mpeg': ['.mp3'],
  'audio/aac': ['.aac'],
  'audio/amr': ['.amr'],
  'audio/midi': ['.midi'],
  'audio/x-m4a': ['.m4a'],

  // Document Formats
  'application/pdf': ['.pdf'],
};

export const isAllowedFileType = (file) => {
  const fileType = file.type;
  const fileExtension = '.' + file.name.split('.').pop().toLowerCase();

  if (allowedFileTypes[fileType]) {
    return allowedFileTypes[fileType].includes(fileExtension);
  }

  return false;
};

export const getAllowedFileTypes = () => {
  return Object.fromEntries(
    Object.entries(allowedFileTypes).map(([mimeType, extensions]) => [
      mimeType,
      extensions,
    ])
  );
};