import { format, isToday, isYesterday, isThisWeek, isThisYear } from 'date-fns';

export function formatDate(date) {
    if (!date) return '';

    const messageDate = new Date(date);

    if (isToday(messageDate)) {
        return format(messageDate, 'h:mm a');
    } else if (isYesterday(messageDate)) {
        return `Yesterday ${format(messageDate, 'h:mm a')}`;
    } else if (isThisWeek(messageDate)) {
        return format(messageDate, 'EEE h:mm a');
    } else if (isThisYear(messageDate)) {
        return format(messageDate, 'MMM d');
    } else {
        return format(messageDate, 'MMM d, yyyy');
    }
}
