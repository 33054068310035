import React, { useState } from 'react';
import { Layout, Typography, Descriptions, Input } from 'antd';
import { useDataContext } from '../DataContext';

const { Sider } = Layout;
const { Title } = Typography;

const ContactInfoSidebar = ({ visible }) => {
  const { contactInfo, updateContact, selectedConversationSid } = useDataContext();
  const [editingField, setEditingField] = useState(null);
  const [editedValue, setEditedValue] = useState('');

  if (!visible || !selectedConversationSid) return null;

  const currentContactInfo = contactInfo[selectedConversationSid] || {};

  const handleFieldClick = (field) => {
    setEditingField(field);
    setEditedValue(currentContactInfo[field] || '');
  };

  const handleValueChange = (e) => {
    setEditedValue(e.target.value);
  };

  const handleValueSave = () => {
    updateContact(selectedConversationSid, { [editingField]: editedValue });
    setEditingField(null);
  };

  const renderField = (label, field) => (
    <Descriptions.Item label={label}>
      {editingField === field ? (
        <Input
          value={editedValue}
          onChange={handleValueChange}
          onPressEnter={handleValueSave}
          onBlur={handleValueSave}
          autoFocus
        />
      ) : (
        <span
          onClick={() => handleFieldClick(field)}
          style={{ 
            cursor: 'pointer', 
            borderBottom: '1px dashed #d9d9d9',
          }}
        >
          {currentContactInfo[field] || 'N/A'}
        </span>
      )}
    </Descriptions.Item>
  );

  return (
    <Sider 
      width={300} 
      style={{ 
        background: '#fff', 
        padding: '20px',
        borderLeft: '1px solid #e8e8e8'
      }}
    >
      <Title level={4}>Contact Information</Title>
      <Descriptions column={1}>
        <Descriptions.Item label="Phone">{currentContactInfo.phoneNumber || 'N/A'}</Descriptions.Item>
        {renderField("Name", "name")}
        {renderField("Email", "email")}
        {renderField("Date of Birth", "dateOfBirth")}
        {renderField("Notes", "notes")}
        {renderField("Fitness Level", "fitnessLevel")}
        {renderField("Session Frequency", "sessionFrequency")}
        {renderField("Scheduled Sessions", "scheduledSessions")}
      </Descriptions>
    </Sider>
  );
};

export default ContactInfoSidebar;