import React from 'react';
import { Button } from 'antd';
import { FileOutlined, FilePdfOutlined, FileImageOutlined, FileWordOutlined, FileExcelOutlined, FilePptOutlined, FileZipOutlined, FileTextOutlined, FileUnknownOutlined } from '@ant-design/icons';
import { useFileUpload } from './FileUploadZone';

const FilePreview = () => {
  const { attachedFile, clearFile } = useFileUpload();

  if (!attachedFile) return null;

  const getFileIcon = (fileType) => {
    switch (true) {
      case fileType.startsWith('image/'): return <FileImageOutlined />;
      case fileType === 'application/pdf': return <FilePdfOutlined />;
      case fileType.includes('word'): return <FileWordOutlined />;
      case fileType.includes('excel') || fileType.includes('spreadsheet'): return <FileExcelOutlined />;
      case fileType.includes('powerpoint') || fileType.includes('presentation'): return <FilePptOutlined />;
      case fileType.includes('zip') || fileType.includes('compressed'): return <FileZipOutlined />;
      case fileType.includes('text'): return <FileTextOutlined />;
      default: return <FileUnknownOutlined />;
    }
  };

  const renderPreview = () => {
    if (attachedFile.type.startsWith('image/')) {
      return <img src={URL.createObjectURL(attachedFile)} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'contain' }} />;
    } else if (attachedFile.type === 'application/pdf') {
      return <embed src={URL.createObjectURL(attachedFile)} type="application/pdf" width="100" height="100" />;
    } else if (attachedFile.type.startsWith('video/')) {
      return <video src={URL.createObjectURL(attachedFile)} width="100" height="100" controls />;
    } else if (attachedFile.type.startsWith('audio/')) {
      return <audio src={URL.createObjectURL(attachedFile)} controls style={{ width: '200px' }} />;
    } else {
      return getFileIcon(attachedFile.type);
    }
  };

  return (
    <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: '10px', width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {renderPreview()}
      </div>
      <div style={{ flexGrow: 1 }}>
        <div>{attachedFile.name}</div>
        <div style={{ fontSize: '0.8em', color: '#888' }}>{attachedFile.type}</div>
      </div>
      <Button type="text" onClick={clearFile}>Remove</Button>
    </div>
  );
};

export default FilePreview;