import React, { useState, useEffect } from 'react';
import { Button, Form, Input } from "antd";
import { EnterOutlined } from '@ant-design/icons';
import styles from '../assets/Conversation.module.css';
import { useDataContext } from '../DataContext';
import { useFileUpload } from './FileUploadZone';

const MessageInput = () => {
  const { selectedConversationSid, saveDraft, getDraft, sendMessage } = useDataContext();
  const { attachedFile, clearFile } = useFileUpload();
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    // Load draft for the selected conversation
    const draft = getDraft(selectedConversationSid);
    setNewMessage(draft || '');
  }, [selectedConversationSid, getDraft]);

  const onMessageChanged = (event) => {
    const message = event.target.value;
    setNewMessage(message);
    saveDraft(selectedConversationSid, message);
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (newMessage.trim() || attachedFile) {
      await sendMessage(selectedConversationSid, newMessage.trim(), attachedFile);

      if (attachedFile) {
        clearFile();
      }
      else {
        setNewMessage('');
        saveDraft(selectedConversationSid, '');
      }
    }
  };

  return (
    <Form onSubmit={handleSendMessage} style={{ paddingBottom: '10px' }}>
      <Input.Group compact={true} style={{
        width: "100%",
        display: "flex",
        flexDirection: "row"
      }}>
        <Input
          style={{ flexBasis: "100%" }}
          placeholder="Type your message here..."
          type="text"
          name="message"
          id={styles['type-a-message']}
          autoComplete="off"
          disabled={!selectedConversationSid}
          onChange={onMessageChanged}
          value={newMessage}
        />
        <Button
          icon={<EnterOutlined />}
          htmlType="submit"
          type="primary"
          onClick={handleSendMessage}
          disabled={!selectedConversationSid}
        />
      </Input.Group>
    </Form>
  );
};

export default MessageInput;