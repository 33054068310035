import React from 'react';
import { Layout, Typography, Badge } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import { useAuthContext } from '../AuthContext';
import { ReactComponent as Logo } from "../assets/twilio-mark-red.svg";
import { HeaderItem } from "./HeaderItem";

const { Header } = Layout;
const { Text } = Typography;

const HeaderComponent = () => {
  const { authStatus, logout } = useAuthContext();

  const getStatusMessage = () => {
    switch (authStatus) {
      case 'connected':
        return 'Connected';
      case 'connecting':
        return 'Connecting...';
      case 'disconnected':
        return 'Disconnected';
      case 'error':
        return 'Connection Error';
      default:
        return 'Unknown Status';
    }
  };

  const getStatusType = () => {
    switch (authStatus) {
      case 'connected':
        return 'success';
      case 'connecting':
        return 'processing';
      case 'disconnected':
      case 'error':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Header className="app-header" style={{ display: "flex", alignItems: "center", padding: 0, width: '100%' }}>
      <div style={{ maxWidth: "250px", width: "100%", display: "flex", alignItems: "center" }}>
        <HeaderItem style={{ paddingRight: "0", display: "flex" }}>
          <Logo />
        </HeaderItem>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <HeaderItem style={{ float: "right", marginLeft: "auto" }}>
          <span style={{ color: "white" }}>{getStatusMessage()}</span>
          <Badge dot={true} status={getStatusType()} style={{ marginLeft: "1em" }} />
        </HeaderItem>
        <HeaderItem>
          <PoweroffOutlined
            onClick={logout}
            style={{ color: "white", fontSize: "20px", marginLeft: "auto" }}
          />
        </HeaderItem>
      </div>
    </Header>
  );
};

export default HeaderComponent;