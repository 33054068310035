import React, { useState, useRef } from 'react';
import '../assets/Conversation.css';
import FileUploadZone from './FileUploadZone';
import FilePreview from './FilePreview';
import styles from '../assets/Conversation.module.css'
import { Layout } from "antd";
import ConversationsMessages from "./ConversationsMessages.js";
import ConversationHeader from "./ConversationHeader.js";
import { useDataContext } from '../DataContext';
import ContactInfoSidebar from './ContactInfoSidebar';
import MessageInput from './MessageInput';

const { Content } = Layout;

const Conversation = () => {
  const { selectedConversationSid, messagesByConversation } = useDataContext();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const messagesEndRef = useRef(null);

  const messages = selectedConversationSid ? messagesByConversation[selectedConversationSid] || [] : [];

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <Layout style={{ height: '100%' }}>
      <ConversationHeader
        onInfoClick={toggleSidebar}
        isSidebarVisible={sidebarVisible}/>
      <Layout style={{ flex: 1, overflowY: 'auto' }}>
        <Content style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
          <FileUploadZone>
            <div className={styles.messages} style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
              <ConversationsMessages messages={messages}/>
              <FilePreview />
              <MessageInput />
              <div ref={messagesEndRef} />
            </div>
          </FileUploadZone>
        </Content>
        <ContactInfoSidebar visible={sidebarVisible} />
      </Layout>
    </Layout>
  );
};

export default Conversation;