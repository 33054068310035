import React, { useState } from 'react';
import { Button, Popover, Input, message } from 'antd';
import { useDataContext } from '../DataContext';

const defaultPhoneNumber = process.env.NODE_ENV === 'development' ? '16469320089' : '';

const NewConversationButton = () => {
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState(defaultPhoneNumber);
    const { createConversation } = useDataContext();

    const showPopover = (visible) => {
        setIsPopoverVisible(visible);
    };

    const handleCreate = async () => {
        setIsPopoverVisible(false);
        try {
            const newConversation = await createConversation(newPhoneNumber);
            setNewPhoneNumber(defaultPhoneNumber); // Reset the phone number input
        } catch (error) {
            console.error('Error creating conversation:', error);
            message.error(error.response?.data?.error || 'Failed to create conversation');
        }
    };

    const handleCancel = () => {
        setIsPopoverVisible(false);
    };

    const handlePhoneNumberChange = (e) => {
        setNewPhoneNumber(e.target.value);
    };

    const popoverContent = (
        <div>
            <Input
                placeholder="Enter phone number"
                value={newPhoneNumber}
                onChange={handlePhoneNumberChange}
                style={{ marginBottom: '10px' }}
            />
            <Button type="primary" onClick={handleCreate} style={{ marginRight: '10px' }}>
                Create
            </Button>
            <Button onClick={handleCancel}>
                Cancel
            </Button>
        </div>
    );

    return (
        <Popover
            content={popoverContent}
            title="New Message"
            trigger="click"
            open={isPopoverVisible}
            onOpenChange={showPopover}
        >
            <Button 
                type="link" 
                onClick={() => showPopover(true)}
                style={{ 
                    color: '#666', 
                    display: 'flex', 
                    alignItems: 'center', 
                    padding: '0'
                }}
            >
                + Send new message
            </Button>
        </Popover>
    );
};

export default NewConversationButton;