import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001',
});

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        
        if (decodedToken.exp < currentTime) {
          handleTokenExpiration();
        }
        
        config.headers['Authorization'] = `Bearer ${token}`;
      } catch (error) {
        handleTokenExpiration();
      }
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

function handleTokenExpiration() {
  window.dispatchEvent(new CustomEvent('tokenExpired', {
    detail: { message: 'Your session has expired. Please log in again.' }
  }));
}

export default instance;
