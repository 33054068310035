import React from "react";
import { Layout } from "antd";
import { useDataContext } from '../DataContext';
import { useAuthContext } from '../AuthContext';

import "../assets/Conversation.css";
import "../assets/ConversationSection.css";
import "../assets/Sidebar.css";

import Conversation from "./Conversation";
import LoginPage from "./LoginPage";
import HeaderComponent from "./HeaderComponent";
import ConversationsSidebar from "./ConversationsSidebar";

const { Content } = Layout;

const ConversationsApp = () => {
    const { authStatus } = useAuthContext();
    const { selectedConversationSid } = useDataContext();

    if (authStatus === 'disconnected' || authStatus === 'error') {
        return <LoginPage />;
    }

    let conversationContent;
    if (authStatus === 'connecting') {
        conversationContent = <div>Connecting...</div>;
    } else if (!selectedConversationSid) {
        conversationContent = <div>Select a conversation</div>;
    } else {
        conversationContent = <Conversation/>;
    }

    return (
        <div className="conversations-window-wrapper">
            <Layout className="conversations-window-container">
                <HeaderComponent />
                <Layout>
                    <ConversationsSidebar />
                    <Content className="conversation-section">
                        <div id="SelectedConversation" style={{ width: '100%', height: '100%' }}>
                            {conversationContent}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
};

export default ConversationsApp;