import MessageBubble from "./MessageBubble";
import React, { useEffect, useRef } from "react";
import { useDataContext } from '../DataContext';
import { useAuthContext } from '../AuthContext';

const ConversationMessages = () => {
  const { selectedConversationSid, messagesByConversation } = useDataContext();
  const { name: identity } = useAuthContext();
  const messagesEndRef = useRef(null);
  
  const messages = selectedConversationSid ? messagesByConversation[selectedConversationSid] || [] : [];

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [messages]);

  return (
    <div id="messages" style={{ flex: 1, overflowY: 'auto', paddingRight: '10px' }}>
      <ul>
        {messages.map(m => (
          <MessageBubble
            key={m.index}
            direction={m.author === identity ? "outgoing" : "incoming"}
            message={m}
          />
        ))}
      </ul>
      <div ref={messagesEndRef} />
    </div>
  );
}

export default ConversationMessages;