import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useAuthContext } from './AuthContext';
import notificationIcon from './assets/icons8-notification.svg';
import axiosInstance from './axiosInstance';

const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

// There are three main scenarios to consider:
// 1. Initial page load -> conversationAdded event
// 2. New conversation added -> conversationAdded event, conversationUpdated event
// 3. Contact info updated -> participantUpdated event
export const DataProvider = ({ children }) => {
  const [conversations, setConversations] = useState([]);
  const [contactInfo, setContactInfo] = useState({});
  const [selectedConversationSid, setSelectedConversationSid] = useState(null);
  const [unreadConversations, setUnreadConversations] = useState(new Set());
  const [messagesByConversation, setMessagesByConversation] = useState({});
  const [drafts, setDrafts] = useState({});
  const { client, authStatus } = useAuthContext();

  const saveDraft = (conversationSid, draft) => {
    setDrafts(prevDrafts => {
      const newDrafts = { ...prevDrafts };
      if (draft === null) {
        delete newDrafts[conversationSid];
      } else {
        newDrafts[conversationSid] = draft;
      }
      localStorage.setItem('messageDrafts', JSON.stringify(newDrafts));
      return newDrafts;
    });
  };

  const getDraft = (conversationSid) => {
    return drafts[conversationSid] || '';
  };

  useEffect(() => {
    const storedDrafts = localStorage.getItem('messageDrafts');
    if (storedDrafts) {
      setDrafts(JSON.parse(storedDrafts));
    }
  }, []);

  const fetchContactInfo = async (conversationSid) => {
    const response = await axiosInstance.get(`/api/conversation/contact-info?conversationSid=${conversationSid}`);
    const newContactInfo = response.data;

    setContactInfo(prevInfo => {
      if (JSON.stringify(prevInfo[conversationSid]) !== JSON.stringify(newContactInfo)) {
        return { ...prevInfo, [conversationSid]: newContactInfo };
      }
      return prevInfo;
    });
  };

  const updateContact = async (conversationSid, updates) => {
    const response = await axiosInstance.post('/api/conversation/update-contact', { conversationSid, updates });
    
    // Update local state
    setContactInfo(prevInfo => ({
      ...prevInfo,
      [conversationSid]: {
        ...prevInfo[conversationSid],
        ...updates
      }
    }));
  };

  const createConversation = async (phoneNumber) => {
    const response = await axiosInstance.post('/createConversation', { webAppUser: client.user.identity, phoneNumber });
    return response.data.conversationSid;
  };

  const deleteConversation = async (conversationSid) => {
    const response = await axiosInstance.delete(`/delete-conversation/${conversationSid}`);
    const result = response.data;

    setConversations(prevConversations =>
      prevConversations.filter(conv => conv.sid !== conversationSid)
    );

    if (selectedConversationSid === conversationSid) {
      setSelectedConversationSid(null);
    }
  };

  const markConversationAsRead = (conversationSid) => {
    setUnreadConversations(prev => {
      const newSet = new Set(prev);
      newSet.delete(conversationSid);
      return newSet;
    });
  };

  const loadMessages = async (conversation) => {
    const messagePaginator = await conversation.getMessages();
    setMessagesByConversation(prev => ({
      ...prev,
      [conversation.sid]: messagePaginator.items
    }));
  };

  const handleMessageAdded = (message) => {
    setMessagesByConversation(prev => ({
        ...prev,
        [message.conversation.sid]: [...(prev[message.conversation.sid] || []), message]
    }));

    if (message.author !== client.user.identity) {
      setUnreadConversations(prev => new Set(prev).add(message.conversation.sid));
      showNotification(message);
    }
  };
  
  const initConversation = async (conversation) => {
    if (conversations.some(conv => conv.sid === conversation.sid)) {
      console.log('skipping init, convo already exists');
      return;
    };
    setConversations(prevConversations => [...prevConversations, conversation]);
    await loadMessages(conversation);

    // console.log(`Adding messageAdded listener for conversation ${conversation.sid}`);
    conversation.on('messageAdded', handleMessageAdded);

    // For new conversations, contact info is unavailable for a few ms, bc the API does 2 steps
    // 1. create conversation, 2. add participants. So we run into a race condition.
    // It is effectively available after loadMessages, but we wait >=1/4 second to ensure it's available.
    const minWaitTime = 250; // 1/4 second in milliseconds
    const conversationAge = Date.now() - new Date(conversation.dateCreated).getTime();
    const waitTime = Math.max(0, minWaitTime - conversationAge);
    // console.log('Waiting for', waitTime, 'ms before fetching contact info');
    await new Promise(resolve => setTimeout(resolve, waitTime));
    fetchContactInfo(conversation.sid);
  };
  
  useEffect(() => {
    if (authStatus === 'connected' && client) {
      // Both initial load and new conversation addition trigger the `conversationAdded` event. 
      // New conversation also triggers `conversationUpdated` when adding participants.
      client.on('conversationAdded', (conversation) => {
        initConversation(conversation);
      });

      client.on('conversationRemoved', (conversation) => {
        setConversations(prev =>
          prev.filter(conv => conv.sid !== conversation.sid)
        );

        setMessagesByConversation(prev => {
          const newState = { ...prev };
          delete newState[conversation.sid];
          return newState;
        });
        conversation.removeAllListeners();
      });

      
      // Adding or updating participants triggers this.
      client.on('participantUpdated', ({ participant }) => {
        fetchContactInfo(participant.conversation.sid);
      });

      return () => {
        if (client) {
          client.removeAllListeners();
          conversations.forEach(c => {
            console.log(`Removing all listeners for conversation ${c.sid}`);
            c.removeAllListeners();
          });
          setConversations([]);
        }
      };
    }
  }, [authStatus, client]);

  const showNotification = (message) => {
    if (Notification.permission === "granted") { //  && document.hidden
      const notification = new Notification(`New Message from ${message.author}`, {
        body: `${message.body}`,
        icon: notificationIcon
      });

      notification.onclick = function() {
        window.focus();
        notification.close();
      };
    }
  };

  const sendMessage = async (conversationSid, messageBody, attachedFile) => {
    if (attachedFile) {
      const originalConversation = conversations.find(conv => conv.sid === selectedConversationSid);

      await originalConversation.sendMessage({
        contentType: attachedFile.type,
        media: attachedFile,
      });
    }
    else {
      const response = await axiosInstance.post('/api/send-message', {
        conversationSid,
        message: messageBody,
        author: client.user.identity
      });

      const result = response.data;
    }
  };

  const removePhoneNumber = async (phoneNumber) => {
    await axiosInstance.post('/removePhoneNumber', { phoneNumber });
  };

  const value = {
    conversations,
    contactInfo,
    fetchContactInfo,
    selectedConversationSid,
    setSelectedConversationSid,
    messagesByConversation,
    createConversation,
    deleteConversation,
    updateContact,
    unreadConversations,
    markConversationAsRead,
    drafts,
    saveDraft,
    getDraft,
    sendMessage,
    removePhoneNumber
  };

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext };