import React from 'react';
import ConversationsApp from './components/ConversationsApp';
import { AuthProvider } from './AuthContext';
import { DataProvider } from './DataContext';
import './assets/App.css';
import 'antd/dist/reset.css';

const App = () => (
  <AuthProvider>
    <DataProvider>
      <ConversationsApp />
    </DataProvider>
  </AuthProvider>
);

export default App;