import React, { createContext, useState, useContext, useEffect } from 'react';
import { Client as ConversationsClient } from "@twilio/conversations";
import { message } from 'antd';

const AuthContext = createContext();
const API_URL = process.env.REACT_APP_API_URL;

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [name, setName] = useState('');
  const [client, setClient] = useState(null);
  const [authStatus, setAuthStatus] = useState('disconnected');

  const login = async (username, password) => {
    try {
      setAuthStatus('connecting');
      
      const authResponse = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (!authResponse.ok) {
        const errorData = await authResponse.json();
        throw new Error(errorData.message);
      }

      const { authToken, twilioToken } = await authResponse.json();

      setName(username);
      setAuthStatus('connected');
      localStorage.setItem("name", username);
      localStorage.setItem("authToken", authToken);
      localStorage.setItem("twilioToken", twilioToken);
      
      const newClient = await initializeClient(twilioToken);
      setClient(newClient);
      await requestNotificationPermission();
    } catch (error) {
      setAuthStatus('error');
      throw error;
    } 
  };

  const signup = async (username, password) => {
    try {
      const response = await fetch(`${API_URL}/auth/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Signup failed');
      }

      // After successful signup, log in the user
      await login(username, password);
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    setAuthStatus('disconnected');
    setName('');
    localStorage.removeItem("name");
    localStorage.removeItem("authToken");
    localStorage.removeItem("twilioToken");
    if (client) {
      client.removeAllListeners();
      client.shutdown().then(() => {
        setClient(null);
      });
    }
  };

  const initializeClient = async (token) => {
    const newClient = new ConversationsClient(token);
    try {
      await newClient.initialize();
      newClient.on('connectionStateChanged', (state) => {
        setAuthStatus(state);
      });
      return newClient;
    } catch (error) {
      console.error('Error initializing client:', error);
      throw error;
    }
  };

  const refreshClientConnection = async () => {
    if (client && client.connectionState !== 'connected') {
      console.log('Refreshing client connection...');
      try {
        await client.shutdown();
        const newClient = await initializeClient(localStorage.getItem("twilioToken"));
        setClient(newClient);
      } catch (error) {
        console.error('Error refreshing client connection:', error);
      }
    }
  };

  const requestNotificationPermission = async () => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      return;
    }

    if (Notification.permission !== "granted") {
      await Notification.requestPermission();
    }
  };

  useEffect(() => {
    const storedName = localStorage.getItem("name");
    const storedAuthToken = localStorage.getItem("authToken");
    const storedTwilioToken = localStorage.getItem("twilioToken");
    if (storedName && storedAuthToken && storedTwilioToken) {
      setName(storedName);
      setAuthStatus('connected');
      initializeClient(storedTwilioToken).then(newClient => {
        setClient(newClient);
      });
    }
  }, []);

  useEffect(() => {
    const handleTokenExpired = (event) => {
      message.error(event.detail.message, 2, () => {
        logout();
      });
    };

    window.addEventListener('tokenExpired', handleTokenExpired);

    return () => {
      window.removeEventListener('tokenExpired', handleTokenExpired);
    };
  }, []);

  useEffect(() => {
    if (client) {
      const handleConnectionStateChange = (state) => {
        const statusMap = {
          connected: 'connected',
          connecting: 'connecting',
          disconnected: 'disconnected'
        };
        const newStatus = statusMap[state] || 'error';
        console.log(`Connection state changed to: ${newStatus}`);
        setAuthStatus(newStatus);
      };

      client.on('connectionStateChanged', handleConnectionStateChange);

      return () => {
        client.off('connectionStateChanged', handleConnectionStateChange);
      };
    }
  }, [client]);

  const value = {
    name,
    client,
    authStatus,
    login,
    logout,
    signup,
    initializeClient,
    refreshClientConnection,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };