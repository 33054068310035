import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Layout, List, Typography, Button } from "antd";
import { useDataContext } from '../DataContext';
import conversationsListStyles from "../assets/ConversationsList.module.css";
import conversationsItemStyles from "../assets/ConversationsItem.module.css";
import { joinClassNames } from "../utils/class-name";
import NewConversationButton from './NewConversationButton';
import RemovePhoneNumberComponent from './RemovePhoneNumberComponent';
import { formatDate } from '../utils/dateUtils';

const { Sider } = Layout;
const { Text } = Typography;

const ConversationsSidebar = () => {
    const {
        conversations,
        selectedConversationSid,
        setSelectedConversationSid,
        contactInfo,
        messagesByConversation,
        unreadConversations,
        markConversationAsRead,
    } = useDataContext();

    const [sortedConversations, setSortedConversations] = useState([]);

    useEffect(() => {
        const sortConversations = () => {
            const sorted = [...conversations].sort((a, b) => {
                // Get the most recent message for each conversation. 
                const lastMessageA = messagesByConversation[a.sid]?.at(-1);
                const lastMessageB = messagesByConversation[b.sid]?.at(-1);
    
                // Compare dates, defaulting to 0 if no messages exist
                return (lastMessageB?.dateCreated || 0) - (lastMessageA?.dateCreated || 0);
            });
            setSortedConversations(sorted);
        };
        sortConversations();
    }, [conversations, messagesByConversation]);

    useEffect(() => {
        if (selectedConversationSid) {
          markConversationAsRead(selectedConversationSid);
        }
      }, [selectedConversationSid]);

    const handleConversationClick = (conversation) => {
        setSelectedConversationSid(conversation.sid);
    };

    const getMostRecentMessageInfo = (conversationSid) => {
        const messages = messagesByConversation[conversationSid] || [];
        if (messages.length > 0) {
            const recentMessage = messages[messages.length - 1];
            return {
                body: recentMessage.body,
                date: recentMessage.dateCreated
            };
        }
        return { body: '', date: null };
    };

    const sortedConversationsWithUnread = useMemo(() => {
        return sortedConversations.map(conversation => ({
            ...conversation,
            hasUnread: unreadConversations.has(conversation.sid)
        }));
    }, [sortedConversations, unreadConversations]);

    return (
        <Sider className="sidebar" width={350}>
            <div style={{ padding: '16px', borderBottom: '1px solid #e8e8e8' }}>
                <NewConversationButton />
            </div>
            <List
                className={conversationsListStyles['conversations-list']}
                bordered={true}
                loading={sortedConversations.length === 0}
                dataSource={sortedConversationsWithUnread}
                renderItem={item => {
                    const activeChannel = item.sid === selectedConversationSid;
                    const conversationItemClassName = joinClassNames([
                        conversationsItemStyles['conversation-item'],
                        activeChannel && conversationsItemStyles['conversation-item--active'],
                        item.hasUnread && conversationsItemStyles['conversation-item--unread']
                    ]);

                    const contactName = contactInfo[item.sid]?.name || 'Unknown';
                    const { body: recentMessage, date: messageDate } = getMostRecentMessageInfo(item.sid);

                    return (
                        <List.Item
                            key={item.sid}
                            onClick={() => handleConversationClick(item)}
                            className={conversationItemClassName}
                        >
                            <div className={conversationsItemStyles['conversation-item-content']}>
                                <div className={conversationsItemStyles['conversation-item-header']}>
                                    <Text
                                        strong
                                        className={conversationsItemStyles['conversation-item-text']}
                                    >
                                        {contactName}
                                    </Text>
                                    <Text
                                        className={conversationsItemStyles['conversation-item-date']}
                                    >
                                        {formatDate(messageDate)}
                                    </Text>
                                </div>
                                <div className={conversationsItemStyles['conversation-item-preview']}>
                                    <Text
                                        className={conversationsItemStyles['conversation-item-message']}
                                    >
                                        {recentMessage}
                                    </Text>
                                </div>
                            </div>
                        </List.Item>
                    );
                }}
            />
            {process.env.NODE_ENV === 'development' && (
                <RemovePhoneNumberComponent/>
            )}
        </Sider>
    );
};

export default ConversationsSidebar;